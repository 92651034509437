/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/* Complete */

@font-face {
  font-family: "RiformaLLWeb-Regular";
  src: url("RiformaLLWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "RiformaLLWeb-Regular";
  src: url("RiformaLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "RiformaLLWeb-Medium";
  src: url("RiformaLLWeb-Medium.woff") format("woff");
}

@font-face {
  font-family: "RiformaLLWeb-Medium";
  src: url("RiformaLLWeb-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "RiformaLLWeb-Regular";
  src: url("RiformaLLWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "RiformaLLWeb-Regular";
  src: url("RiformaLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "RiformaLLWeb-Medium";
  src: url("RiformaLLWeb-Medium.woff") format("woff");
}

@font-face {
  font-family: "RiformaLLWeb-Medium";
  src: url("RiformaLLWeb-Medium.woff2") format("woff2");
}


/* subset */

@font-face {
  font-family: "RiformaLLSub-RegularSubset";
  src: url("RiformaLLSub-RegularSubset.woff2") format("woff2");
}

@font-face {
  font-family: "RiformaLLSub-RegularSubset";
  src: url("RiformaLLSub-RegularSubset.woff") format("woff");
}

@font-face {
  font-family: "RiformaLLSub-MediumSubset";
  src: url("RiformaLLSub-MediumSubset.woff2") format("woff2");
}

@font-face {
  font-family: "RiformaLLSub-MediumSubset";
  src: url("RiformaLLSub-MediumSubset.woff") format("woff");
}

@font-face {
  font-family: "RiformaLLSub-RegularSubset";
  src: url("RiformaLLSub-RegularSubset.woff2") format("woff2");
}

@font-face {
  font-family: "RiformaLLSub-RegularSubset";
  src: url("RiformaLLSub-RegularSubset.woff") format("woff");
}

@font-face {
  font-family: "RiformaLLSub-MediumSubset";
  src: url("RiformaLLSub-MediumSubset.woff2") format("woff2");
}

@font-face {
  font-family: "RiformaLLSub-MediumSubset";
  src: url("RiformaLLSub-MediumSubset.woff") format("woff");
}



@import "variables";
@import "collapse_menu";
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "bootstrap-select/sass/bootstrap-select";
@import "components/checkbox";

* {
  font-family: "RiformaLLWeb-Medium";
}

::selection {
  background-color: $white !important;
  color: $primary !important;
}

html,
body {
  height: 100%;
  background-color: #e6e6e6;
}

.flash-message-danger{
  padding: 0.5em 1em;
  border-radius: 2em;
  border: 3px solid #ff0000;
  background: #ff0000;
  color: black;

  button{
    color: black;
    text-shadow: none !important;
    opacity: 1 !important;

    &:hover{
      color: black;
    }
  }
}

.flash-message-warning{
  padding: 0.5em 1em;
  border-radius: 2em;
  border: 3px solid $warning;
  background: $warning;
  color: black;

  button{
    color: black;
    text-shadow: none !important;
    opacity: 1 !important;

    &:hover{
      color: black;
    }
  }
}

.flash-message-success{
  padding: 0.5em 1em;
  border-radius: 2em;
  border: 3px solid #00ff00;
  background: #00ff00;
  color: black;

  button{
    color:black;
    text-shadow: none !important;
    opacity: 1 !important;

    &:hover{
      color:black;
    }
  }
}

.return-to {
  // font-weight: bold;
  font-size: 1.1em;
}

@media (max-width: 1270px) {
  html,
  body {
    background-color: black;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }

  .return-to {
    color: $white;
  }
}

@-moz-document url-prefix() {
  textarea:required, input:required {
    box-shadow:none;
  }
}

.error-message{
  list-style: none;
  color: red;

  &::before{
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00d";
    padding-right: 5px;
  }
}

.title-black {
  color: $primary;
}

@media (max-width: 1270px) {
  .title-black {
    color: $white;
  }

}

.form-control {
  border: $border-size solid #fff;
}

.btn-primary {
  border: solid 1px $white;

  &:hover {
    background-color: $white;
    color: $primary;
  }
}

.btn-light {
  border: 2px solid white !important;
}

@media (max-width: 1270px) {
  .flash-message {
    position: absolute;
    width: 100%;
    margin-top: 3em;
  }
}
@media (min-width: 1270px) {
  .flash-message {
    position: absolute;
    width: 50%;
    left: 35%;
  }
}

.bfd-special-letter {
  font-family: "RiformaLLWeb-Regular";
}

.login {
  top: 20%;
  position: relative;
}

.form-login {
  margin-top: 3em;
  margin-bottom: 3em;
  background-color: $primary;
  padding-bottom: 2em;

  .form-title {
    background-color: $primary;
    color: white;
    padding: 1em;
  }

  .form-group {
    padding: 1em;
    margin-bottom: 0;
  }

  .btn {
    margin-right: 1em;
  }

  .form-control {
    margin-bottom: 0.5em;
  }

  .btn-forget-password {
    color: rgba(255, 255, 255, 0.5);

    &:hover {
      color: $white;
    }
  }
}

.flex-container {
  display: flex;
  min-height: 100%;

  @media (max-width: 1270px) {
    .hamburger {
      z-index: 1;
      opacity: 1;
      position: absolute;
      right: 1em;
      top: 0.5em;

      .hamburger-icon {
        font-size: 1.5em;
        color: $white;
      }
    }
    .nav {
      height: 100%;
      width: 16em;
      background-color: $primary;

      #hideRight {
        color: white;
        width: 0;
        padding: 0;
        border: none;
        font-size: 3em;
        position: relative;
        left: 4.2em;

        &:hover {
          // color: black;
          cursor: pointer;
        }
      }

      .nav-title {
        background-image: url("../images/bestfindoors_logo_negative.svg");
        background-repeat: no-repeat;
        width: 90%;
        height: 4em;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2em;
        margin-bottom: 2em;
      }

      .nav-link {
        padding: 1rem;
        color: white;

        &:hover {
          color: $primary;
          background-color: $primary-light;
        }
        &.active {
          color: $primary;
          background-color: $white-gray;
        }
      }
    }
  }
  @media (min-width: 1270px) {
    .hamburger {
      position: absolute;
      left: -16em;
    }

    .nav {
      height: 100%;
      width: 24em;
      background-color: $primary;

      #hideRight {
        position: absolute;
        left: -24em;
      }

      .nav-title {
        background-image: url("../images/bestfindoors_logo_negative.svg");
        background-repeat: no-repeat;
        width: 90%;
        height: 4em;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2em;
        margin-bottom: 2em;
      }

      .nav-link {
        padding: 1rem;
        color: white;

        &:hover {
          color: $primary;
          background-color: $primary-light;
        }
        &.active {
          color: $primary;
          background-color: $white-gray;
        }
      }
    }
  }

  .content {
    width: 100%;

    table {
      margin-top: 3em;
    }
  }
}

@media (max-width: 992px) {
  .profile-padding{
    padding-left: 0 !important;
  }
}



.profile-container {
  background-color: $primary;
  color: $white;

  .enterprise-name {
    input {
      font-size: 1.5em;
    }
  }

  @media (max-width: 576px) {
    .profile-mobile-padding{
      padding-left: 0 !important;
    }
  }

  @media (max-width: 1270px) {
    .profile-mobile-padding{
      padding-left: 0 !important;
    }
  }

  @media(min-width: 768px){
    .article-image-row{
      padding-left: 1em !important;
    }
  }

  @media (min-width: 1270px) {
    .article-image-row{
      padding-left: 0 !important;
    }
  }
}

.profile-info {
  height: 100%;

  .profile-edit-name {
    border: $border-size solid white;
    border-radius: 20px;

    i {
      float: right;
      padding-right: 1em;
      padding-top: 1em;
    }

    input {
      font-size: 2em;
      border: none;
      // font-weight: bold;
      width: 45%;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .profile-name {
    input {
      font-size: 2em;
    }
  }

  .profile-input-group {
    &:focus-within {
      border-color: rgba(255, 255, 255, 0.5);
    }

  }
}

.article-title{
  margin-top: -0.2em;
}

@media (min-width: 1270px) {
  .profile-subinfo{
    padding-left: 0 !important;
  }
}

@media (max-width: 1270px) {
  .profile-info {
    // padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 1em;
  }

  .profile-subinfo {
    padding-right: 0 !important;
  }

  .profile-firstname{
    padding-right: 0.5em;
  }

  .profile-lastname{
    padding-left: 0.5em;
  }
}

@media (max-width: 768px) {
  .profile-firstname{
    padding-right: 0;
  }

  .profile-lastname{
    padding-left: 0;
  }
}

.card,
.profile-card {
  // padding-bottom: 2em;
  background-color: $primary;
  border: $border-size solid;
  border-color: $white;

  .profile-icon-card{
    margin-left: -0.5em;
  }

  .profile-icon-social {
    font-size: 1.5em;
    display: inline-block;
    width: 1.2em;
  }
  .profile-icon {
    font-size: 1.5em;
    display: inline-block;
    width: 2em;
  }
  .profile-icon-large {
    font-size: 2em;
    display: inline-block;
    width: 1.5em;
    text-align: center;
  }
  .profile-icon-url{
    line-break: anywhere;
    position: absolute;
    margin-right: 1em;
  }
  .profile-icon-adress{
    line-break: anywhere;
    position: absolute;
    margin-top: 0.9em;
    margin-right: 1em;
  }

  .profile-svg-icon{
    position: absolute;
    margin-right: 1em;
    line-break: anywhere;
    // line-height: 1;
  }

  .icon-social{
    display: inline;
  }
}

.profile-edit {
  .profile-card-content {
    border: $border-size solid white;
    border-radius: 25px;
    padding: 1em;

    input, .phone-format {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #828282;
      font-family: "RiformaLLWeb-Regular";

      &:required:after {
        content: "* ";
      }
    }

    .phone-format{
      padding-left: 0;

      select{
        position: relative;
        bottom: 0.5em;
        width: min-content;
        background-color: black;
        color: white;
        border: 2px solid white;
        border-radius: 20px;
        margin-right: 0.5em;
        padding: 0.2em 0.5em;
      }

      input{
        position: relative;
        bottom: 0.5em;
        width: 125px;
        background-color: black;
        color: white;
        border: none;
        padding: 0.2em 0.5em;
      }
    }

    @media (max-width: 1411px) {
      .phone-format{
        select{
          width: 40%;
        }

        input{
          width: 55%;
        }
      }
    }

    @media (max-width: 1270px) {
      .phone-format{
        select{
          width: 45%;
        }

        input{
          width: 50%;
        }
      }
    }

    select {
      font-family: "RiformaLLWeb-Regular";
    }
  }

  .profile-category {
    label {
      font-size: 1rem;
    }
  }
}

.article-card {
  border-color: $white;
  background-color: $primary;
}

.article-card-content {
  border: $border-size solid white;
  border-radius: 25px;
  padding: 1em;
}

.article-category {
  // padding-bottom: 4em !important;

  label {
    font-size: 1rem;
  }

  p.float-left {
    display: contents !important;
  }
}
// @media (max-width: 1322px) {
//   .article-category {
//     padding-bottom: 7em !important;
//   }
// }
.truncate-filename{
    input[type=file] {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
  }
}

.input-file-font{
  font-family: "RiformaLLWeb-Medium" !important;
}

.logo {
  position: relative;
  width: 100%;

  &:hover .circle {
    opacity: 1;
  }

  .circle {
    border-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #00000099;

    .circle-blur {
      filter: blur(4px);
      border-bottom: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      transition: 0.5s ease;
      background-color: #00000099;
    }

    .circle-text {
      z-index: 1;
      color: white;
      font-size: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }

  .circle-link {
    text-decoration: none;
    width: 100%;
    .circle-no-image {
      display: block;
      width: 100%;
      height: auto;
      border: $border-size solid white;
      border-radius: 25px;

      .circle-text {
        z-index: 1;
        color: white;
        font-size: 14px;
        position: relative;
        top: 50%;
        text-align: center;

        &:hover {
          background-color: $white;
          color: $primary !important;
          border-radius: 20px;
        }
      }
    }
  }

  .logo-user {
    display: block;
    width: 100%;
    height: auto;
  }
}


.logo-article {
  position: relative;
  // width: 40%;

  &:hover .circle {
    opacity: 1;
  }

  .circle {
    border-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #00000099;

    .circle-blur {
      filter: blur(4px);
      border-bottom: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      transition: 0.5s ease;
      background-color: #00000099;
    }

    .circle-text {
      z-index: 1;
      color: white;
      font-size: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }

  .circle-link {
    text-decoration: none;
    width: 100%;

    .circle-no-image {
      display: block;
      width: 100%;
      height: auto;
      border: $border-size solid white;
      border-radius: 25px;

      .circle-text {
        z-index: 1;
        color: white;
        font-size: 1rem;
        position: relative;
        top: 50%;
        text-align: center;

        &:hover {
          background-color: $white;
          color: $primary !important;
          border-radius: 20px;
        }
      }
    }
  }

  .logo-user {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .article-padding{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

h2.article-title.article-title-size {
  word-break: break-all;
}

.article {
  @media (max-width: 1270px) {
    .article-title {
      background-color: $primary;
      color: $primary;

      h4 {
        margin: 0;
        padding: 0.6em 0.3em 0.3em 0.6em !important;
        border-left: $border-size solid;
        border-right: $border-size solid;
        border-top: $border-size solid;
        border-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
      p {
        margin: 0;
        padding: 0.9em 0.3em 0.9em 0.9em !important;
        border-left: 2px solid;
        border-right: 2px solid;
        border-bottom: 2px solid;
        border-color: white;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
    .article-preface {
      padding: 0 1em 0 1em;
      background-color: $primary;
      border-left: $border-size solid $white;
      border-right: $border-size solid $white;

      a {
        background-size: cover;
        width: 100%;
        height: 35vh;
        display: block;
        background-position-x: center;
        background-position-y: center;

        &:hover {
          opacity: 0.5;
          transition: 0.3s ease-in-out;
        }
      }

      p {
        position: relative;
        bottom: 3em;
        text-align: center;
        background-color: rgba(143, 155, 255, 0.8);
        color: $primary;
        height: 3em;
      }
    }

    .article-no-category{
      border-left: $border-size solid $white;
      border-right: $border-size solid $white;
      border-bottom: $border-size solid $white;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  @media (min-width: 1270px) {
    .article-title {
      background-color: $primary;
      color: white;

      h4 {
        margin: 0;
        padding: 0.3em 0.3em 0.3em 0.7em;
      }

      p{
        margin-left: 1em;
      }
    }
    .article-preface {
      padding: 0 1em 1em 1em;
      background-color: $primary;

      a {
        background-size: cover;
        width: 100%;
        height: 35vh;
        display: block;
        background-position-x: center;
        background-position-y: center;

        &:hover {
          opacity: 0.5;
          transition: 0.3s ease-in-out;
        }
      }

      p {
        position: relative;
        bottom: 3em;
        text-align: center;
        background-color: rgba(143, 155, 255, 0.8);
        color: white;
        height: 3em;
      }
    }
  }
}
.article-show {
  // background-color: $primary;
  .article-image {
    img {
      background-size: cover;
      width: 100%;
      height: auto;
    }
  }
}

.user-image-new{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.article-image-new{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (max-width: 1270px) {
  .article-image-row {
    padding-right: 0 !important;
  }
}

@media (max-width: 576px) {
  .article-row {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.article-editing {
  background-color: $primary;
  color: $white;

  input,
  select {
    font-family: "RiformaLLWeb-Regular";
  }

  .article-back-logo{
    margin-left: 0 !important;
    vertical-align: baseline !important;
  }

  @media (min-width: 768px) {
    .article-right-row{
      padding-left: 0 !important;
    }
  }

  @media (max-width: 1178px) {
    .article-back-logo{
      margin-left: 0 !important;
    }
  }
  @media (max-width: 1270px) {
    .article-back-logo{
      margin-left: 0 !important;
      // margin-top: 1em !important;
    }
    .margin-logo-mobile {
      margin-top: 8em;
    }
  }
}

@media (max-width: 1178px) {
  // .image-save{
  //   margin-bottom: 0.5em !important;
  // }
  .delete-image {
    display: table-cell !important;
  }
  .image-back{
    margin-left: 0 !important;
  }
}


.user-logo-editing {
  background-color: $primary;
  color: $white;
}

.content-article {
  .form-control {
    height: 40%;
  }

  input,
  textarea {
    font-family: "RiformaLLWeb-Regular";
  }
}

.icon-user {
  width: 2em;
  border-radius: 50%;
}
.profile-link {
  color: $red;
}

label.required:after {
  content: " *";
  color: red;
}

.adress-required::after{
  content: " *";
  color: red;
}

.category-required::after{
  content: " *";
  color: red;
}

.category {
  background-color: $primary;
  padding: 1em;
  color: $white;

  table {
    color: $white;
  }
}

.user {
  background-color: $primary;
  padding: 1em;
  color: $white;

  table {
    color: $white;

    a,
    li {
      color: $white;
    }
  }
}

@media (max-width: 1270px) {
  // .steplist-overflow{
  //     overflow-x: auto;
  // }
}
@media (max-width: 1270px) {
  .craue_formflow_steplist {
    margin-top: 1em;
    // width: 200%;
    .craue_formflow_current_step {
      margin-right: 10em;
    }
  }
}

.craue_formflow_steplist {
  height: 7em;
  list-style: none;
  counter-reset: my-awesome-counter;

  li {
    float: left;
    width: 16.66%;
    counter-increment: my-awesome-counter;
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 9px;
    float: left;
    position: relative;
    letter-spacing: 1px;
    text-align: center;

    @media (min-width: 768px) {
      p {
        color: black;
        font-size: 10px;
        padding-right: 1em;
        padding-left: 1em;
      }
    }

    &:first-child::after {
      content: none;
    }

    &:before {
      content: counter(my-awesome-counter);
      width: 1.5em;
      height: 1.5em;
      line-height: 1.5em;
      display: block;
      font-size: 24px;
      background: $white;
      color: $primary;
      border: solid 1px $primary;
      border-radius: 25px;
      margin: 0 auto 10px auto;
    }

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background: $primary;
      position: absolute;
      left: -50%;
      top: 1.8em;
      z-index: -1;
    }
  }

  .craue_formflow_current_step {
    &:before {
      content: counter(my-awesome-counter);
      color: white;
      font-weight: bold;
      background-color: $primary;
      padding: 0.2em;
      border-radius: 50%;
      line-height: 1.2em;
    }

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background: $primary;
      position: absolute;
      left: -50%;
      top: 1.8em;
      z-index: -1;
    }
  }

  .craue_formflow_done_step {
    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      color: white;
      font-weight: bold;
      background-color: $primary;
      padding: 0.2em;
      border-radius: 50%;
      line-height: 1.2em;
    }

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background: $primary;
      position: absolute;
      left: -50%;
      top: 1.8em;
      z-index: -1;
    }
  }
}

.createUser {
  color: $white;
  background-color: $primary;
  padding: 1em;

  .form-title {
    h1{
      margin-left: 0.2em !important;
    }
  }
}

@media (max-width: 1270px) {
  .createUser {
    padding: 1em 1em 1em 0;
  }
}

.tinymce-register {
  width: 100%;
  background-color: black;
  color: white;
  border: none;
  border-radius: 20px;
  // padding: 0.5em;
  outline: none;
  &::-webkit-resizer {
    color: white;
  }
}
#article_content {
  &::-webkit-input-placeholder {
    // color: white;
  }
  width: 100%;
  background-color: black;
  color: white;
  // border: $border-size solid;
  border-radius: 20px;
  padding: 0.5em;
  outline: none;
  &::-webkit-resizer {
    color: white;
  }
}

.profile-textarea{
  padding-left: 0.8em !important;
}

.container-with-border {
  border: $border-size solid $white;
  border-radius: 20px;
  padding: 1em;

  label {
    font-size: 1.5em;
  }

  textarea {
    font-family: "RiformaLLWeb-Regular";
    border: none;
  }

  input {
    font-family: "RiformaLLWeb-Regular";
    border: none;
  }
}

.container-with-border-soft-padding{
  border: $border-size solid $white;
  border-radius: 20px;
  padding: 0.7em;

  label {
    font-size: 1.5em;
  }

  textarea {
    font-family: "RiformaLLWeb-Regular";
    border: none;
  }

  input {
    font-family: "RiformaLLWeb-Regular";
    border: none;
  }
}

.container-with-border-small {
  border: $border-size solid $white;
  border-radius: 20px;

  label {
    font-size: 1.5em;
  }

  textarea {
    border: none;
    font-family: "RiformaLLWeb-Regular";
  }

  input {
    font-family: "RiformaLLWeb-Regular";
    border: none;
    &:focus {
      box-shadow: none;
    }
  }

  i {
    position: relative;
    margin-right: 1em;
    top: 1em;
    float: right;
  }
}

.category-table {
  margin-top: 0.5em !important;
}

.input-number-without-arrow {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

th[role="columnheader"]:not(.no-sort) {
  cursor: pointer;
}

th[role="columnheader"]:not(.no-sort):after {
  content: "";
  float: right;
  margin-top: 7px;
  border-width: 0 4px 4px;
  border-style: solid;
  border-color: #dedede transparent;
  visibility: hidden;
  opacity: 0;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

th[aria-sort="ascending"]:not(.no-sort):after {
  border-bottom: none;
  border-width: 4px 4px 0;
}

th[aria-sort]:not(.no-sort):after {
  visibility: visible;
  opacity: 0.4;
}

th[role="columnheader"]:not(.no-sort):hover:after {
  visibility: visible;
  opacity: 1;
}

.custom-file {
  width: 100%;
  height: 100%;
  flex: none !important;

  .custom-file-label {
    background-color: transparent;
    border: none;
    // color: $white;
    // border-radius: 0;
    // border-bottom: 1px solid $primary;

    &::after {
      content: "";
      background-color: transparent;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  input {
    position: absolute !important;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }
}

.terms{
  margin-bottom: 1em;
  margin-left: 0.5em;
}

@media (max-width: 768px) {
  .terms{
    margin-left: 1.7em;
  }
}

@media (max-width: 434px) {
  .email-register{
    margin-left: 0.5em;
  }
  .reset-button{
    margin-left: 0.5em;
  }
}

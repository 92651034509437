/* General styles for all menus */
@media (max-width: 1270px) {
	.hamburger{
		#showRight{
			background-color: $primary;
		}
	}
	.cbp-spmenu {
		background: $primary;
		position: fixed;

		button {
			color: #fff;
			position: relative;
			left: 5.5em;
			font-size: 2em;
			padding-top: 10px;
			margin: 0;
			font-weight: 300;
		}
		a {
			display: block;
			color: #fff;
			font-size: 1.1em;
			font-weight: 300;

			&:hover {
				background: $primary;
			}
			&:active {
				background: $primary;
				color: #fff;
			}
		}
	}
}

@media (min-width: 1270px) {
	.cbp-spmenu {
		background: $primary;

		button {
			color: #fff;
			position: relative;
			left: 5.5em;
			font-size: 2em;
			padding-top: 10px;
			margin: 0;
			font-weight: 300;
		}
		a {
			display: block;
			color: #fff;
			font-size: 1.1em;
			font-weight: 500;

			&:hover {
				background: $primary;
			}
			&:active {
				background: $primary;
				color: #fff;
			}
		}
	}
}

/* Orientation-dependent styles for the content of the menu */

.cbp-spmenu-vertical {
	width: 240px;
	height: 100%;
	top: 0;
	z-index: 1000;
}

.cbp-spmenu-vertical a {
	border-bottom: 1px solid #fff;
	padding: 1em;
}

.cbp-spmenu-horizontal {
	width: 100%;
	height: 150px;
	left: 0;
	z-index: 1000;
	overflow: hidden;
}

.cbp-spmenu-horizontal h3 {
	height: 100%;
	width: 20%;
	float: left;
}

.cbp-spmenu-horizontal a {
	float: left;
	width: 20%;
	padding: 0.8em;
	border-left: 1px solid #fff;
}

/* Vertical menu that slides from the left or right */

@media (max-width: 1270px) {
	.cbp-spmenu-left {
		left: -16em;
	}
}

@media (min-width: 1270px) {
	.cbp-spmenu-left {
		left: 0px;
	}
}

@media (max-width: 1270px) {
	.cbp-spmenu-right {
		right: -16em;
	}
}

@media (min-width: 1270px) {
	.cbp-spmenu-right {
		left: 0px;
	}
}

// .cbp-spmenu-right {
// 	right: -240px;
// }

.cbp-spmenu-left.cbp-spmenu-open {
	left: 0;
}

.cbp-spmenu-right.cbp-spmenu-open {
	right: 0px;
}

Horizontal menu that slides from the top or bottom

.cbp-spmenu-top {
	top: -150px;
}

.cbp-spmenu-bottom {
	bottom: -150px;
}

.cbp-spmenu-top.cbp-spmenu-open {
	top: 0px;
}

.cbp-spmenu-bottom.cbp-spmenu-open {
	bottom: 0px;
}


/* Transitions */

.cbp-spmenu,
.cbp-spmenu-push {
    z-index: 10;
	transition: all 0.3s ease;
}
$primary: #000;
$white: #fff;
$white-gray: #E6E6E6;
$primary-light: $white;
$light: $white;
$red: #ff0000;
$border-size: 2px;
// $link-color: rgba(255,255,255, 0.5);
// $link-hover-color: $white;

.btn-primary{
    border: 2px solid $white !important;
}

$h1-font-size:                4em;
$h2-font-size:                2.5em;
$h3-font-size:                2em;
$h4-font-size:                1.5em;
$h5-font-size:                1em;
$h6-font-size:                0.75em;

$input-bg:  $primary;
$input-color: $white;
$input-border-color: $white;
$input-placeholder-color: rgba(255,255,255, 0.5);
$input-border-width: 2px;
$input-border-radius:  20px;

$btn-border-radius: 20px;
// @media screen and (-webkit-min-device-pixel-ratio:0) {
//     $btn-font-weight: 500 !important;
// }
$btn-font-weight: 500;

$card-border-radius: 20px;
$card-border-width: 2px;
$card-border-color: $red;
// $card-color: $white;
// $card-bg: $primary;
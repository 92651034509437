$colors: #8282ff, #ff0, #0ff, #ff9600, #7f00ff, #ff0092, #f00, #00f,
  #0f0, #ff8282;

@for $i from 1 through length($colors) {
//   @debug $i;
  $color: nth($colors, $i);
  #checkbox-#{$i} {
    color: $color !important;
  }
}

@-moz-document url-prefix()
{
    .checkbox-container
    {
        display: flex;
    }
}

#categoryCheckbox{
    display: flex;
    flex-direction: column;

    .checkbox-container
    {
        cursor: pointer;
        color: $white;
        display: flex;

        input
        {
            width: 0;
            height: 0;
            // margin-right: 1.5em;
            margin: 0 !important;
            cursor: pointer;

            opacity: 0;

            &:checked ~ .checkmark
            {
                background-color: $primary;

                &:after
                {
                    display: block;
                }
            }
        }

        .checkmark
        {
            // position: absolute;
            // left: 1em;
            min-width: 1em;
            width: 1em;
            height: 1em;
            margin-top: .2em;
            margin-left: .2em;
            margin-right: 0.5em;

            border: 1px solid $white;
            background-color: transparent;

            &::after
            {
                font-size: 2em;
                position: relative;
                right: 3px;
                bottom: 20px;
                display: none;
                width: 5px;
                height: 10px;
                content: '\22A0';
                color: $white;
            }
        }

        &:hover input ~ .checkmark
        {
            background-color: $white;
            &::after
            {
                color: $primary;
            }
        }

        @-moz-document url-prefix()
        {
            label
            {
                // margin-left: 2em !important;
            }
        }
    }
}